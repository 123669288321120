import React from "react";

import PreviewCompatibleImage from "./utilities/PreviewCompatibleImage";
import FunnelForm from "./FunnelForm";

export default function Signup({ signupObject, isActive = true }) {
  return (
    <div className="flex flex-wrap items-center">
      <div className="w-full lg:w-1/2 lg:pr-2">
        <PreviewCompatibleImage className="rounded-xl" imageObject={signupObject.imageObject} />
      </div>
      <div className="w-full lg:w-1/2 pt-10 md:pt-0 lg:pl-2">
        <FunnelForm data={signupObject} isActive={isActive} />
      </div>
    </div>
  );
}
