export default function appendBranchAndSite(target) {
  const branch = document.getElementById("#splittestBranchData")?.value;
  const pathname = window.location.host + window.location.pathname;
  if (target.search) {
    target.search = target.search.concat(`&site=${pathname}`);
  } else {
    target.search = `?site=${pathname}`;
  }
  if (branch) {
    target.search = target.search.concat(`&branch=${branch}`);
  }
}
