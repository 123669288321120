import React from "react";

import { navigate, Link } from "gatsby";
import appendTrackingParameters from "../../functions/appendTrackingParameters";
import appendBranchAndSite from "../../functions/appendBranchAndSite";

const TrackedLink = ({
  children,
  href = "#start",
  className,
  beforeOnClick,
  onClick,
}) => {
  const navigateOnClick = (e) => {
    // Click has e.code === undefined, onKeyDown should only work if user presses enter
    if (e.code === undefined || e.code === "Enter") {
      e.preventDefault();

      if (window.dataLayer) {
        window.dataLayer.push({
          event: e.type,
          buttonText: e.currentTarget.innerText,
          origin: window.location.host + window.location.pathname,
          target: href,
          branch: document.getElementById("#splittestBranchData")?.value,
        });
      }

      // Checks if link is same site anchor link. If so navigate directly to anchor.
      if (href.startsWith("#")) {
        window.location.href = href;
        return;
      }

      // Checks if Link is a file download. If so, it will open the file in a new tab.
      const file = /\.[0-9a-z]+$/i.test(href);
      if (file) {
        window.open(href, "_blank", "noreferrer noopener");
        return;
      }

      // Append query string parameter for use on new page
      href = appendTrackingParameters(href);

      // Concat host for internal links so it can be used with URL Constructor
      if (!(href.startsWith("https://") || href.startsWith("http://"))) {
        href = window.location.origin + href;
      }
      const target = new URL(href);

      const hrefInternal = target.pathname + target.search + target.hash;

      // Appends Branch and Site Data for it to be availabe on external sites (e.g. Order App)
      appendBranchAndSite(target);

      const hrefExternal = target.href;

      // If the Link is internal and isn't a anchor link (see above condition for same site anchor links)
      if (target.host === window.location.host) {
        navigate(hrefInternal);
        // If the Link is external
      } else {
        const newTabExceptions = [
          // "achtsamkeitsakademie.de",
          "achtsamkeitsacademy.de",
        ];

        const windowName = newTabExceptions.some((element) =>
          target.host.includes(element)
        )
          ? "_self"
          : "_blank";

        window.open(hrefExternal, windowName, "noreferrer noopener");
      }
    }
  };

  // External links should only use https
  if (href.startsWith("http://")) {
    href.replace("http://", "https://");
  }

  // Https is added in case CMS user doesn't specify it
  if (
    !href.startsWith("/") &&
    !href.startsWith("#") &&
    !href.startsWith("https://")
  ) {
    href = `https://${href}`;
  }

  if (href.startsWith("/")) {
    // Returns Gatsby-Link for internal Links
    return (
      <Link
        to={href}
        className={className}
        onClick={onClick || navigateOnClick}
        onKeyDown={onClick || navigateOnClick}
      >
        {children}
      </Link>
    );
  } else {
    // Return a-Links for external Links or same site anchor links
    return (
      <a
        href={href}
        className={className}
        onClick={(e) => {
          if (beforeOnClick) {
            beforeOnClick(e);
          }
          if (onClick) {
            onClick(e);
          } else {
            navigateOnClick(e);
          }
        }}
        onKeyDown={(e) => {
          if (beforeOnClick) {
            beforeOnClick(e);
          }
          if (onClick) {
            onClick(e);
          } else {
            navigateOnClick(e);
          }
        }}
      >
        {children}
      </a>
    );
  }
};

export default TrackedLink;
