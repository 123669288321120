import React, { useState, useEffect } from "react";

export default function withBooleanPropChangeAfterDate(
  Component,
  changingPropName,
  date,
  isFalseAfterDate = false
) {
  return function ComponentWithBooleanPropChangeAfterDate({ ...props }) {
    // set default state to status before date, which is derived from isFalseAfterDate prop; setting default state directly to condition not working, when state changes
    const [bool, setBool] = useState(isFalseAfterDate);
    // after date this returns true
    setBool(date.ts < Date.now());
    // add changing property to props object
    props[changingPropName] = bool;
    // if state after date should be false, revert previous bool
    if (isFalseAfterDate) {
      props[changingPropName] = !props[changingPropName];
    }

    useEffect(() => {
      setBool(date.ts < Date.now());
    }, []);

    return (
      <>
        <Component {...props} />
      </>
    );
  };
}
