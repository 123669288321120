import React from "react";

import PreviewCompatibleImage from "./utilities/PreviewCompatibleImage";

const Header = ({ imageObject }) => {
  return (
    <header className="flex justify-center py-1 sm:py-3 px-5 font-normal landscape:h-[20vh] landscape:lg:h-[15vh] h-[15vh] md:h-[10vh] max-h-32">
      <PreviewCompatibleImage
        imageObject={imageObject}
        loading="eager"
        className="h-full max-w-screen-lg"
        objectFit="contain"
      />
    </header>
  );
};

export default Header;