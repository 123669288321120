import React, { useEffect, useState } from "react";
import Layout from "../components/Layout.js";
import Section from "../components/Section.js";
import { graphql, navigate } from "gatsby";
import { Helmet } from "react-helmet";
import BrandButton from "../components/BrandButton.js";
import RenderMarkdown from "../components/utilities/RenderMarkdown.js";
import PreviewCompatibleImage from "../components/utilities/PreviewCompatibleImage.js";

const NotFoundPageTemplate = ({ data }) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (
      data.isBackButtonEnabled &&
      document.referrer.includes(data.seoObject.domain)
    ) {
      setVisible(true);
    }
  }, []);

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: "de-DE",
        }}
        title={data.seoObject.title || "Seite nicht gefunden"}
        meta={[
          {
            name: "description",
            content: "",
          },
          {
            name: "robots",
            content: "noindex",
          },
        ]}
      />

      <Layout imageObject={data.headerObject.imageObject}>
        <Section>
          <div className="mx-auto flex flex-col items-center">
            {data.heroObject.title && (
              <RenderMarkdown
                markdownContent={data.heroObject.title}
                className="text-center"
                whitespace="normal"
              />
            )}
            <PreviewCompatibleImage
              imageObject={data.heroObject.imageObject}
              className="max-w-2xl my-10"
            />
            <RenderMarkdown
              markdownContent={data.heroObject.description}
              className="text-center max-w-2xl"
            />
            <div className="space-y-5 flex flex-col">
              <BrandButton href={data.heroObject.ctaObject.linkUrl}>
                <RenderMarkdown
                  markdownContent={data.heroObject.ctaObject.linkText}
                  isMarkdown={false}
                  whitespace="normal"
                />
              </BrandButton>
              {visible && (
                <BrandButton onClick={() => navigate(-1)}>
                  <RenderMarkdown
                    markdownContent="Zurück"
                    isMarkdown={false}
                    whitespace="normal"
                  />
                </BrandButton>
              )}
            </div>
          </div>
        </Section>
      </Layout>
    </>
  );
};

const NotFoundPage = ({ data }) => {
  return <NotFoundPageTemplate data={data.markdownRemark.frontmatter} />;
};

export default NotFoundPage;

export const pageQuery = graphql`
  query NotFoundPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        seoObject {
          domain
          title
        }
        headerObject {
          imageObject {
            alt
            image {
              childImageSharp {
                gatsbyImageData(placeholder: NONE)
              }
            }
          }
        }
        heroObject {
          title
          imageObject {
            alt
            image {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
          description
          ctaObject {
            linkText
            linkUrl
          }
          isBackButtonEnabled
        }
      }
    }
  }
`;
