import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout.js";
import Section from "../components/Section.js";
import Video from "../components/Video.js";
import BrandButton from "../components/BrandButton.js";
import RenderMarkdown from "../components/utilities/RenderMarkdown.js";

export const VideoMessagePageTemplate = ({ data }) => {
  data.heroObject.video.format = "Hochformat";

  return (
    <Layout seoObject={data.seoObject} isWithHeader={false}>
      <Section>
        <div className="space-y-5 sm:space-y-10">
          <Video videoObject={data.heroObject.video} loading="eager" />
          <div className="flex justify-center">
            <BrandButton href={data.heroObject.ctaObject.linkUrl}>
              <RenderMarkdown
                markdownContent={data.heroObject.ctaObject.linkText}
                isMarkdown={false}
                whitespace="normal"
              />
            </BrandButton>
          </div>
        </div>
      </Section>
    </Layout>
  );
};

const VideoMessagePage = ({ data }) => {
  return <VideoMessagePageTemplate data={data.markdownRemark.frontmatter} />;
};

export default VideoMessagePage;

export const pageQuery = graphql`
  query VideoMessagePage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        seoObject {
          slug
          domain
          description
          index
          title
          imageObject {
            alt
            image {
              childImageSharp {
                resize(width: 1200) {
                  src
                  height
                  width
                }
              }
            }
          }
        }
        heroObject {
          ctaObject {
            linkText
            linkUrl
          }
          video {
            cloudflareId
            hasGifThumbnail
          }
        }
      }
    }
  }
`;
