const defaultTheme = require("tailwindcss/defaultTheme");

module.exports = {
  content: ["./src/**/*.{js,jsx,ts,tsx}"],
  theme: {
    fontFamily: {
      sans: ["Montserrat", "ui-sans-serif", "system-ui", "sans-serif"],
    },
    fontWeight: {
      normal: 300,
      medium: 500,
      semibold: 600,
    },
    colors: {
      current: "currentColor",
      inherit: "inherit",
      white: "#ffffff",
      "brand-gray": "#5f5e5e",
      "brand-black": "#2c2a29",
      "brand-beige-100": "#d6d3d2",
      "brand-beige-40": "#efeded",
      "brand-gold": "#daaa00",
      "brand-mint": "#bcc8a5",
      "brand-olive": "#8ca279",
      "brand-green": "#4d6039",
      "brand-blue": "#759fab",
      "brand-denim": "#3f6978",
      "offbrand-red": "#DC2626",
    },
    screens: {
      xs: "320px",
      ...defaultTheme.screens,
      landscape: { raw: "(orientation: landscape)" },
    },
    extend: {
      transitionProperty: {
        height: "height",
      },
      keyframes: {
        pbaaLoaderFrames: {
          "0%, 40%, 80%, 100%": { transform: "scale(1.0) ", opacity: "0.8" },
          "20%, 60%": { transform: "scale(1.5)", opacity: "1.0" },
        },
      },
      animation: {
        pbaaLoader: "pbaaLoaderFrames 2s infinite",
      },
    },
  },
  plugins: [require("@tailwindcss/aspect-ratio")],
};
