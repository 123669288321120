import React from "react";

import RenderMarkdown from "./utilities/RenderMarkdown";
import BrandButton from "./BrandButton";

const PricingSection = ({ pricingObject }) => {
  return (
    <div className="space-y-10 text-center">
      <RenderMarkdown
        markdownContent={pricingObject.title}
        isMarkdown={true}
        whitespace="pre-line"
      />
      <div className="flex flex-col items-center">
        <BrandButton href={pricingObject.linkUrl}>
          <RenderMarkdown
            markdownContent={pricingObject.cta}
            whitespace="normal"
          />
        </BrandButton>
        <RenderMarkdown
          markdownContent={pricingObject.ctaSubtitle}
          whitespace="normal"
        />
      </div>
    </div>
  );
};

export default PricingSection;