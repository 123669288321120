export default function appendTrackingParameters(href) {
  let hrefTemp = href;
  if (!(hrefTemp.startsWith("https://") || hrefTemp.startsWith("http://"))) {
    hrefTemp = window.location.origin + hrefTemp;
  }
  const target = new URL(hrefTemp);

  const url = new URL(document.location.href);
  const searchParams = url.searchParams;

  const params = [
    "utm_medium",
    "utm_source",
    "utm_campaign",
    "utm_term",
    "utm_content",
    "fbclid",
    "gclid",
  ];

  for (let param of params) {
    if (searchParams.has(param)) {
      if (!target.searchParams.has(param)) {
        target.searchParams.append(param, searchParams.get(param) || "");
      }
    }
  }

  let targetString = target.toString();
  targetString = targetString.replace(window.location.origin, "");
  return targetString;
}
