import React from "react";
import { ReactComponent as Wanderlust } from "../img/imported_with_webpack/logo-cloud/wanderlust.svg";
import { ReactComponent as Bestseller } from "../img/imported_with_webpack/logo-cloud/bestseller.svg";
import { ReactComponent as Huffington } from "../img/imported_with_webpack/logo-cloud/huffington.svg";
import { ReactComponent as Focus } from "../img/imported_with_webpack/logo-cloud/focus.svg";
import { ReactComponent as DasErste } from "../img/imported_with_webpack/logo-cloud/daserste.svg";
import { ReactComponent as Bayern1 } from "../img/imported_with_webpack/logo-cloud/bayern1.svg";

const Famous = ({ famousObject, className }) => {
  // {/* <div className={`flex flex-col items-center ${className}`}>
  //   <RenderMarkdown markdownContent={famousObject.title} />
  //   <ImageResponsive imageObject={famousObject.imageObject} />
  // </div> */}

  return (
    <div className="flex flex-wrap md:flex-nowrap w-full justify-evenly">
      <Bayern1 className="w-1/3 md:w-40 max-h-16 sm:max-h-24 p-3"/>
      <DasErste className="w-1/3 md:w-40 max-h-16 sm:max-h-24 p-3"/>
      <Bestseller className="w-1/3 md:w-40 max-h-16 sm:max-h-24 p-3"/>
      <Focus className="w-1/3 md:w-40 max-h-16 sm:max-h-24 p-3"/>
      <Huffington className="w-1/3 md:w-40 max-h-16 sm:max-h-24 p-3"/>
      <Wanderlust className="w-1/3 md:w-40 max-h-16 sm:max-h-24 p-3"/>
    </div>
  );
};

export default Famous;
