import React, { useState, useRef } from "react";
import { navigate } from "gatsby";

import RenderMarkdown from "./utilities/RenderMarkdown.js";
import BrandButton from "./BrandButton.js";
import getCookieByNamesList from "../functions/getCookieByNamesList";
import EmailValidator from "email-validator";
import PbaaLoader from "./PbaaLoader";
import appendTrackingParameters from "../functions/appendTrackingParameters.js";
import { nanoid } from "nanoid";
import axios from "axios";
import { sha256 } from "js-sha256";

export default function FunnelForm({
  data,
  isActive = true,
  classNameForm = "",
  classNameButton = "",
}) {
  const [formInputsState, setFormInputsState] = useState({
    firstName: "",
    email: "",
  });

  const [emailError, setEmailError] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const formRef = useRef(null);

  function onChange(event) {
    const target = event.target;
    const name = target.name;
    const value = target.value;

    setFormInputsState({ ...formInputsState, [name]: value });
  }

  const handleSubmitOnlyEnter = (e) => {
    if (e.key === "Enter") {
      handleSubmit(e);
    }
  };

  const handleSubmit = (e) => {
    // Click has e.code === undefined, onKeyDown should only work if user presses enter
    if (e.code === undefined || e.code === "Enter") {
      e.preventDefault();

      if (
        typeof formRef.current.checkValidity === "function" &&
        typeof formRef.current.reportValidity === "function"
      ) {
        if (!formRef.current.checkValidity()) {
          formRef.current.reportValidity();
        }
      }

      // Validate Name for case browser doesn't support checkValidity or reportValidity
      if (formInputsState.firstName.trim() === "") {
        setNameError(true);
        return;
      } else {
        setNameError(false);
      }
      //  Second email validation because first doesnt check if a TLD is provided
      if (!EmailValidator.validate(formInputsState.email.trim())) {
        setEmailError(true);
        return;
      } else {
        setEmailError(false);
      }

      let trackingData = {
        url: document.location.href,
      };
      
      trackingData.utmMedium = ParamHelper.getParam("utm_medium");
      trackingData.utmSource = ParamHelper.getParam("utm_source");
      trackingData.utmCampaign = ParamHelper.getParam("utm_campaign");
      trackingData.utmTerm = ParamHelper.getParam("utm_term");
      trackingData.utmContent = ParamHelper.getParam("utm_content"); 
      trackingData.fbclid = ParamHelper.getParam("fbclid");
      trackingData.gclid = ParamHelper.getParam("gclid");
      
      const eventId = nanoid();
      const cookieNamesList = ["_fbc", "_fbp", "cookie_consent"];

      trackingData = {
        ...trackingData,
        ...getCookieByNamesList(cookieNamesList),
        event_id: eventId,
      };

      const funnelId = data.funnelId;

      const branch = document.getElementById("#splittestBranchData")?.value;

      const requestData = {
        utmMedium: ParamHelper.getParam("utm_medium"),
        utmSource: ParamHelper.getParam("utm_source"),
        utmCampaign: ParamHelper.getParam("utm_campaign"),
        utmTerm: ParamHelper.getParam("utm_term"),
        utmContent: ParamHelper.getParam("utm_content"),
        firstName: formInputsState.firstName.trim(),
        email: formInputsState.email.toLowerCase().trim(),
        funnelId,
        trackingData,
        branch,
      };

      const url = `https://funnel.api.prod.achtsamkeitsakademie.de/funnels/${funnelId}/participants${
        branch ? `?branch=${branch}` : ""
      }`;

      if (!submitted) {
        setLoading(true);
        setSubmitted(true);
        axios
          .put(url, requestData)

          .then(function (response) {
            if (response.status === 200) {
              if (window.dataLayer) {
                window.dataLayer.push({
                  event: "FunnelForm.submitted",
                  eventId,
                  externalId: sha256(
                    response.data.funnelParticipant.funnelParticipantId
                  ),
                  branch: document.getElementById("#splittestBranchData")
                    ?.value,
                  email: requestData.email,
                  firstName: requestData.firstName.toLowerCase(),
                });
              }

              const hrefWithTrackingParams = appendTrackingParameters(
                data.href
              );

              navigate(hrefWithTrackingParams);
            } else {
              setLoading(false);
              setSubmitted(false);
            }
          })
          .catch(function (error) {
            console.warn(error);
            setLoading(false);
            setSubmitted(false);
          });
      }
    }
  };
  return (
    <>
      <div className="relative">
        <div class={`${loading ? "invisible" : "visible"}`}>
          {data.title && (
            <RenderMarkdown
              markdownContent={data.title}
              className="text-center"
            />
          )}
          {data.subtitle && (
            <RenderMarkdown markdownContent={data.subtitle} isMarkdown={true} />
          )}
          <form
            className={`flex flex-col space-y-3 ${classNameForm}`}
            ref={formRef}
            onSubmit={handleSubmit}
          >
            <input
              autocomplete="given-name"
              name="firstName"
              id="firstName"
              type="text"
              placeholder="Vorname"
              className={`p-2 rounded-md disabled:bg-brand-beige-100 border-2 text-brand-black ${
                nameError ? "border-offbrand-red" : "border-brand-olive"
              }`}
              required
              value={formInputsState.firstName}
              onChange={onChange}
              disabled={!isActive}
              onKeyDown={handleSubmitOnlyEnter}
            />
            {nameError && (
              <p className="text-sm font-medium text-offbrand-red">
                Bitte gib deinen Namen ein.
              </p>
            )}
            <input
              autocomplete="email"
              name="email"
              id="email"
              type="email"
              placeholder="Deine E-Mail"
              className={`p-2 rounded-md disabled:bg-brand-beige-100 border-2 text-brand-black ${
                emailError ? "border-offbrand-red" : "border-brand-olive"
              }`}
              required
              value={formInputsState.email}
              onChange={onChange}
              disabled={!isActive}
              onKeyDown={handleSubmitOnlyEnter}
            />
            {emailError && (
              <p className="text-sm font-medium text-offbrand-red">
                Bitte prüfe, ob du deine Email richtig geschrieben hast.
              </p>
            )}

            <BrandButton
              isActive={isActive}
              onClick={handleSubmit}
              className={`flex flex-col ${classNameButton}`}
            >
              <RenderMarkdown
                markdownContent={isActive ? data.cta : "Abgelaufen"}
                whitespace="normal"
              />
            </BrandButton>
          </form>
          {data.ctaSubtitle && (
            <RenderMarkdown
              markdownContent={data.ctaSubtitle}
              isMarkdown={false}
              whitespace="normal"
              className="text-xs sm:text-base text-center"
            />
          )}
        </div>

        <div
          className={`absolute top-1/2 left-1/2 ${
            loading ? "visible" : "invisible"
          }`}
        >
          <PbaaLoader />
        </div>
      </div>
    </>
  );
}

class ParamHelper {
  static getParam(key) {
    if (typeof window === "undefined") {
      return;
    }

    const url = new URL(document.location.href);
    if (url.searchParams.has(key)) return url.searchParams.get(key);

    try {
      const storage = window.sessionStorage;
      if (storage) {
        return storage.getItem(key);
      }
    } catch (e) {
      console.warn(e);
    }

    return;
  }
}
