import { useEffect } from "react";

import Cookies from "universal-cookie";
import { nanoid } from "nanoid";

const cookies = new Cookies();

export default function useSession(cookieName = "CSYID") {
  useEffect(() => {
    let cookieSessionId = cookies.get(cookieName);
    const domain =
      window.location.hostname === "localhost"
        ? "localhost"
        : `.${document.location.hostname}`;
    if (!cookieSessionId) {
      cookieSessionId = nanoid();
      cookies.set(cookieName, cookieSessionId, {
        path: "/",
        domain,
      });
    }
  }, []);

  const getSessionId = () => {
    return cookies.get(cookieName);
  };

  return getSessionId;
}
