import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import Layout from "../components/Layout.js";
import HeroSection from "../components/HeroSection.js";
import ProgressBar from "../components/ProgressBar.js";
import Video from "../components/Video.js";
import Section from "../components/Section.js";
import RenderMarkdown from "../components/utilities/RenderMarkdown.js";
import FurtherInteractions from "../components/FurtherInteractions.js";
import ButtonDayPageNav from "../components/ButtonDayPageNav.js";
import ButtonDayPageCheck from "../components/ButtonDayPageCheck.js";
import BrandButton from "../components/BrandButton.js";
import Audio from "../components/Audio";
import getCookieByName from "../functions/getCookieByName.js";
import addDaysToDate from "../functions/addDaysToDate.js";
import withBooleanPropChangeAfterDate from "../components/HOCs/withBooleanPropChangeAfterDate.js";

export const JammerfastenDayPageTemplate = ({ data }) => {
  const dayOffset = 1;

  const courseDate = addDaysToDate(
    data.configObject.startDate,
    data.configObject.day - dayOffset
  );

  const nextDay = addDaysToDate(courseDate, 1);

  const ButtonDayPageNavActivatedAfterDate = withBooleanPropChangeAfterDate(
    ButtonDayPageNav,
    "isActive",
    nextDay
  );

  function createSlugToPreviousCourseDay() {
    const previousCourseDay = data.configObject.day - 1;
    const previousDaySlug = `/tag-${previousCourseDay}-${data.configObject.year}`;

    return previousDaySlug;
  }

  function createSlugToNextCourseDay() {
    const nextCourseDay = data.configObject.day + 1;
    const nextDaySlug = `/tag-${nextCourseDay}-${data.configObject.year}`;
    return nextDaySlug;
  }

  data.seoObject.slug = `${data.seoObject.slug}-${data.configObject.day}-${data.configObject.year}`;

  const [isClicked, setIsClicked] = useState(false);

  const cookieName = `day${data.configObject.day}-${data.configObject.year}_done`;

  function clickHandler() {
    document.cookie = `${cookieName}=${!isClicked}; max-age=31536000; path=${
      document.location.pathname
    }`;
    setIsClicked(!isClicked);
  }

  useEffect(() => {
    if (
      // getCookieByNamesList([cookieName]) &&
      // getCookieByNamesList([cookieName])[cookieName] === "true"
      getCookieByName(cookieName) &&
      getCookieByName(cookieName) === "true"
    ) {
      setIsClicked(true);
    }
  }, []);

  return (
    <>
      <Helmet>
        <meta
          content={courseDate.ts}
          name="courseDateMeta"
          id="courseDateMeta"
        />
        <script>{`
          if (document.getElementById("courseDateMeta").content > Date.now()) {
            const url = new URL(document.location.href);
            if (!url.searchParams.has("godmode")) {
              window.location.replace("/bald");
            }
          }
        `}</script>
      </Helmet>
      <Layout
        imageObject={data.headerObject.imageObject}
        seoObject={data.seoObject}
      >
        <HeroSection isSectionComponent={true}>
          <div className="flex flex-col justify-center space-y-5 sm:space-y-10 ">
            <ProgressBar
              progressObject={data.heroObject.progressObject}
              isProgressCompleted={isClicked}
            />
            <Video videoObject={data.heroObject.video} loading="eager" />
            {!data.configObject.isLastDay && (
              <ButtonDayPageCheck
                text={`Video gesehen, Meditation gehört? - Tag ${data.configObject.day} abhaken!`}
                textChecked={`Geschafft! - Tag ${data.configObject.day} abgehakt!`}
                type="button"
                onClick={clickHandler}
                isClicked={!isClicked}
                className="mx-auto sm:w-[35ch] lg:w-[55ch]"
              />
            )}
          </div>
        </HeroSection>
        {!data.configObject.isLastDay && (
          <>
            <Section className="" classNameInnerContainer="flex justify-center">
              <RenderMarkdown markdownContent={data.text} />
            </Section>

            {data.libsynList?.[0].libsynId && (
              <Section id="meditation">
                <div className="space-y-5">
                  {data.libsynList.map((item) => (
                    <>
                      <RenderMarkdown
                        markdownContent={item.description}
                        className="text-center"
                      />
                      <Audio key={item.libsynId} libsynId={item.libsynId} />
                    </>
                  ))}
                </div>
              </Section>
            )}
          </>
        )}

        {data.heroObject.ctaObject?.linkText &&
          data.heroObject.ctaObject.linkUrl && (
            <Section className="pt-0 sm:pt-0">
              <div className="flex justify-center">
                <BrandButton
                  href={data.heroObject.ctaObject.linkUrl}
                  className="max-w-md"
                >
                  <RenderMarkdown
                    markdownContent={data.heroObject.ctaObject.linkText}
                    whitespace="normal"
                  />
                </BrandButton>
              </div>
            </Section>
          )}
        {!data.configObject.isLastDay && (
          <>
            <Section>
              <div className="flex flex-row justify-between">
                <div className="w-5/12 sm:w-1/3 flex-none">
                  <ButtonDayPageNav
                    text="Gestern"
                    href={createSlugToPreviousCourseDay()}
                    className={data.configObject.day === 1 && "invisible"}
                  />
                </div>
                <div className="w-5/12 sm:w-1/3">
                  <ButtonDayPageNavActivatedAfterDate
                    text="Morgen"
                    href={createSlugToNextCourseDay()}
                    className={data.configObject.isLastDay && "invisible"}
                    isActive={false}
                    activateOnCountdown={true}
                    countdownDueDate={nextDay}
                    reverse
                  />
                </div>
              </div>
            </Section>

            <Section>
              <FurtherInteractions
                furtherInteractionsObject={data.furtherInteractionsObject}
              />
            </Section>
          </>
        )}
      </Layout>
    </>
  );
};

const JammerfastenDayPage = ({ data }) => {
  return <JammerfastenDayPageTemplate data={data.markdownRemark.frontmatter} />;
};

export default JammerfastenDayPage;

export const pageQuery = graphql`
  query JammerfastenDayPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        configObject {
          startDate
          day
          isLastDay
          year
        }
        seoObject {
          slug
          domain
          description
          index
          title
          imageObject {
            alt
            image {
              childImageSharp {
                resize(width: 1200) {
                  src
                  height
                  width
                }
              }
            }
          }
        }
        headerObject {
          imageObject {
            alt
            image {
              childImageSharp {
                gatsbyImageData(placeholder: NONE)
              }
            }
          }
        }
        heroObject {
          progressObject {
            title
            percentage
            percentageCompleted
          }
          video {
            format
            cloudflareId
            hasGifThumbnail
            embeddedCode
          }
          ctaObject {
            linkText
            linkUrl
          }
        }
        text
        libsynList {
          description
          libsynId
        }
        furtherInteractionsObject {
          fbInteractionObject {
            imageObject {
              alt
              image {
                childImageSharp {
                  gatsbyImageData(placeholder: TRACED_SVG)
                }
              }
            }
            ctaObject {
              linkText
              linkUrl
            }
          }
          premiumInteractionObject {
            imageObject {
              alt
              image {
                childImageSharp {
                  gatsbyImageData(placeholder: TRACED_SVG)
                }
              }
            }
            ctaObject {
              linkText
              linkUrl
            }
          }
        }
      }
    }
  }
`;
