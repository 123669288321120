import React, { useState, useEffect } from "react";
import RenderMarkdown from "./utilities/RenderMarkdown";

const ProgressBar = ({ progressObject, isProgressCompleted = false }) => {

  const progressReached = isProgressCompleted
    ? progressObject.percentageCompleted
    : progressObject.percentage;

  const [progressDisplay, setProgressDisplay] = useState(progressReached);
  const [progressBgColor, setProgressBgColor] = useState("bg-brand-green");

  // reset state variables if progress check button is unchecked
  if (!isProgressCompleted) {
    setProgressDisplay(progressObject.percentage);
    setProgressBgColor("bg-brand-green");
  }

  const animationTimeinMs = 3000;

  useEffect(() => {
    if (
      isProgressCompleted &&
      progressDisplay !== progressObject.percentageCompleted
    ) {
      setProgressBgColor("bg-brand-gold");

      const progressDifference =
        progressObject.percentageCompleted - progressObject.percentage;
      const updateInterval =
        animationTimeinMs / progressDifference <= 50
          ? animationTimeinMs / progressDifference
          : 50;

      const timer = setTimeout(() => {
        setProgressDisplay((current) => current + 1);
      }, updateInterval);

      return () => {
        clearTimeout(timer);
      };
    }
  });

  return (
    <div className="flex flex-col items-center text-center sm:space-y-1">
      {progressObject.title && (
        <RenderMarkdown
          markdownContent={progressObject.title}
          whitespace="normal"
        />
      )}

      <div className="flex border-2 border-brand-green w-full h-7 sm:h-8 rounded-md bg-white">
        <span
          className={`flex justify-end h-full ${progressBgColor}`}
          style={{
            // TODO: translate this inline CSS style to Tailwind CSS if possible
            width: `${progressDisplay}%`,
            transition: "background-color 1s ease",
          }}
        >
          {progressObject.percentage >= 50 && (
            <div className="text-white text-base sm:text-xl pr-1">
              {progressDisplay}%
            </div>
          )}
        </span>
        {progressObject.percentage < 50 && (
          <span className="text-brand-black text-base sm:text-xl pl-1">
            {progressDisplay}%
          </span>
        )}
      </div>
    </div>
  );
};

export default ProgressBar;
