import React from "react";

import TrackedLink from "./utilities/TrackedLink";

export default function BrandButton({
  children,
  className = "",
  color = "bg-brand-gold",
  colorInactive = "bg-brand-mint",
  isActive = true,
  onClick,
  beforeOnClick,
  href,
  textColor = "text-white",
  textColorInactive = "text-brand-beige-40",
}) {
  const classNameActive = `${color} ${textColor} transform hover:scale-[1.02] duration-100 ease-out`;
  const classNameInactive = `${colorInactive} ${textColorInactive} pointer-events-none`; // pointer-events-none deactivates clicking

  return (
    <TrackedLink
      className={`${
        isActive ? classNameActive : classNameInactive
      } px-8 py-4 rounded-xl inline-block text-center text-lg sm:text-2xl ${className}`}
      beforeOnClick={beforeOnClick}
      onClick={onClick}
      href={href}
    >
      {children}
    </TrackedLink>
  );
}
