import React from 'react'

export default function FlexGroup({
  ElementName,
  list,
  className = "",
  classNameElement = "",
  elementPropsObject,
}) {
  return (
    <article className={"flex flex-wrap justify-around " + className}>
      {list.map((item, index) => (
        <ElementName
          key={item.id || index}
          {...item}
          className={classNameElement}
          {...elementPropsObject}
        />
      ))}
    </article>
  );
}