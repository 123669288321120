import React from "react";

import RenderMarkdown from "./utilities/RenderMarkdown";
import { ReactComponent as Check } from "../img/imported_with_webpack/check-solid.svg";

export default function ButtonDayPageCheck({
  text = "",
  textChecked = "",
  className = "",
  isClicked = true,
  onClick,
  href,
}) {
  const buttonClassNameUnchecked =
    "bg-white transform hover:scale-[1.02] duration-100 ease-out text-brand-black shadow-xl border-b border-brand-beige-40";
  const buttonClassNameChecked =
    "bg-white text-brand-black shadow-xl scale-[1.02]";

  const classNameUnchecked =
    "self-center rounded-full bg-brand-green opacity-0 p-2 sm:p-4 h-full w-full";
  const classNameChecked = `self-center rounded-full bg-brand-gold p-2 sm:p-4 h-full w-full`;

  return (
    <button
      className={`${
        isClicked ? buttonClassNameUnchecked : buttonClassNameChecked
      }  px-8 py-2 block text-center text-lg sm:text-2xl ${className}`}
      onClick={onClick}
      href={href}
    >
      <div className="flex flex-row  content-center space-x-3 sm:space-x-5 ">
        <div className="rounded-full h-12 sm:h-20 border">
          <div
            className={`${isClicked ? classNameUnchecked : classNameChecked}`}
            style={{ transition: "background-color 3s ease" }}
          >
            <Check className="fill-current text-white h-8 sm:h-12" />
          </div>
        </div>

        <RenderMarkdown
          markdownContent={isClicked ? text : textChecked}
          isMarkdown={false}
          whitespace="normal"
          className="font-normal flex flex-col justify-center text-center"
        />
      </div>
    </button>
  );
}
