export default function getCookieByNamesList(cookieNamesList) {
  if (typeof cookieNamesList === "undefined") {
    console.error("missing parameter");
    throw new Error("missing parameter");
  }

  const cookies = {};
  for (const cookieName of cookieNamesList) {
    let cookiePrefix = `${cookieName}=`;
    const cookieArr = document.cookie.split(";");
    for (let cookie of cookieArr) {
      while (cookie.charAt(0) === " ")
        cookie = cookie.substring(1, cookie.length);
      if (cookie.indexOf(cookiePrefix) === 0) {
        cookies[cookieName] = cookie.substring(
          cookiePrefix.length,
          cookie.length
        );
      }
    }
  }

  if (Object.keys(cookies).length > 0) {
    return cookies;
  } else {
    return null;
  }
}
