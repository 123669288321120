import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout.js";
import HeroSection from "../components/HeroSection";
import RenderMarkdown from "../components/utilities/RenderMarkdown.js";
import Video from "../components/Video.js";
import BrandButton from "../components/BrandButton.js";

export const VideoPageTemplate = ({ data }) => {
  data.heroObject.video.format = "Querformat";

  return (
    <Layout
      imageObject={data.headerObject.imageObject}
      seoObject={data.seoObject}
    >
      <HeroSection>
        <div className="space-y-5 sm:space-y-10">
          <RenderMarkdown
            markdownContent={data.heroObject.title}
            className="text-center"
            whitespace="normal"
          />
          <Video videoObject={data.heroObject.video} loading="eager" />
          {data.heroObject.ctaObject.linkUrl &&
            data.heroObject.ctaObject.linkText && (
              <div className="flex justify-center">
                <BrandButton href={data.heroObject.ctaObject.linkUrl}>
                  <RenderMarkdown
                    markdownContent={data.heroObject.ctaObject.linkText}
                    whitespace="normal"
                  />
                </BrandButton>
              </div>
            )}
        </div>
      </HeroSection>
    </Layout>
  );
};

const VideoPage = ({ data }) => {
  return <VideoPageTemplate data={data.markdownRemark.frontmatter} />;
};

export default VideoPage;

export const pageQuery = graphql`
  query VideoPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        seoObject {
          slug
          domain
          description
          index
          title
          imageObject {
            alt
            image {
              childImageSharp {
                resize(width: 1200) {
                  src
                  height
                  width
                }
              }
            }
          }
        }
        headerObject {
          imageObject {
            alt
            image {
              childImageSharp {
                gatsbyImageData(placeholder: NONE)
              }
            }
          }
        }
        heroObject {
          ctaObject {
            linkText
            linkUrl
          }
          title
          video {
            cloudflareId
            hasGifThumbnail
          }
        }
      }
    }
  }
`;
