import React from "react";
import Section from "./Section";

export default function HeroSection({
  children,
  Container = "section",
  isSectionComponent = true,
  className = "",
}) {
  // Passing Section component as string (like this: Container="Section") not possible somehow
  // either use isSectionComponent={true} or Container={Section} as property
  // isSection prop overrides Container prop!!
  if (isSectionComponent) {
    Container = Section;
  }
  
  return (
    <Container className={`${className}`}>
      {children}
    </Container>
  );
}
