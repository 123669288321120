import React, { useState, useEffect } from "react";
import TrackedLink from "./utilities/TrackedLink";
import getCookieByName from "../functions/getCookieByName";

const CookieConsent = () => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (!getCookieByName("cookie_consent")) {
      setVisible(true);
    }
  }, []);

  const accept = (e) => {
    // Click has e.code === undefined, onKeyDown should only work if user presses space or enter
    if (e.code === undefined || e.code === "Enter" || e.code === "Space") {
      e.preventDefault();
      document.cookie = "cookie_consent=yes; max-age=31536000; path=/";
      // only needed if we want to send cookies only if user accepts
      /* if (window.dataLayer) {
      window.dataLayer.push({ event: "page_change" });
    } */
      setVisible(false);
    }
  };

  /* const decline = () => {
    // Click has e.code === undefined, onKeyDown should only work if user presses space or enter
    if (e.code === undefined || e.code === "Enter" || e.code === "Space") {
      document.cookie = "cookie_consent=no; max-age=31536000; path=/";
      setVisible(false);
    }
  }; */

  if (!visible) {
    return null;
  }

  return (
    <div class="bg-white border-brand-green border-b text-brand-black text-xs z-30 fixed top-0 w-full p-4 flex items-end lg:items-center">
      <div class>
        Diese Internetseite verwendet Cookies, Google Analytics und den
        Facebook-Pixel für die Analyse und Statistik. Cookies helfen uns, die
        Benutzerfreundlichkeit unserer Website zu verbessern. Durch die weitere
        Nutzung der Website stimmen Sie der Verwendung zu. Weitere Informationen
        hierzu finden Sie in unserer{" "}
        <TrackedLink
          className="underline"
          href="https://docs.achtsamkeitsakademie.de/datenschutzerklaerung"
        >
          Datenschutzerklärung.
        </TrackedLink>
      </div>
      {/* <div class="space-x-5 flex flex-col-reverse sm:flex-row">
        <TrackedLink class="pl-3  mt-5 sm:mt-0" onClick={decline}>
          Ablehnen
        </TrackedLink> */}
      <TrackedLink
        setDefaultHref={false}
        className="rounded-md lg:ml-3 py-2 px-4 bg-brand-green text-white cursor-pointer"
        onClick={accept}
      >
        OK
      </TrackedLink>
      {/* 
      </div> */}
    </div>
  );
};

export default CookieConsent;
