import { useEffect, useState } from "react";

const millisecondsInSecond = 1000;
const millisecondsInMinute = 60 * millisecondsInSecond;
const millisecondsInHour = 60 * millisecondsInMinute;
const millisecondsInDay = 24 * millisecondsInHour;

export default function useCountdown(epochTime) {
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  const [isTimeUp, setIsTimeUp] = useState(getMillisecondsLeft(epochTime) <= 0);

  useEffect(() => {
    if (epochTime && !isTimeUp) {
      const interval = setInterval(() => {
        if (getMillisecondsLeft(epochTime) <= 0) {
          setIsTimeUp(true);
        }

        const currentTimeLeft = getMillisecondsLeft(epochTime);

        setDays(getDaysLeft(currentTimeLeft));
        setHours(getHoursLeft(currentTimeLeft));
        setMinutes(getMinutesLeft(currentTimeLeft));
        setSeconds(getSecondsLeft(currentTimeLeft));
      }, 500);
      return () => {
        clearInterval(interval);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { days, hours, minutes, seconds, isTimeUp };
}

function getMillisecondsLeft(epochTime) {
  return epochTime - new Date().getTime();
}

const getDaysLeft = (epochTime) => Math.floor(epochTime / millisecondsInDay);

const getHoursLeft = (epochTime) =>
  Math.floor(
    (epochTime -
      Math.floor(epochTime / millisecondsInDay) * millisecondsInDay) /
      millisecondsInHour
  );
const getMinutesLeft = (epochTime) =>
  Math.floor(
    (epochTime -
      Math.floor(epochTime / millisecondsInHour) * millisecondsInHour) /
      millisecondsInMinute
  );
const getSecondsLeft = (epochTime) =>
  Math.floor(
    (epochTime -
      Math.floor(epochTime / millisecondsInMinute) * millisecondsInMinute) /
      millisecondsInSecond
  );
