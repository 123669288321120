import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout.js";

import HeroSection from "../components/HeroSection.js";
import Section from "../components/Section";
import RenderMarkdown from "../components/utilities/RenderMarkdown.js";

import { BackgroundImageDivResponsive } from "../components/BackgroundImage.js";
import FlexGroup from "../components/utilities/FlexGroup.js";
import BenefitsList from "../components/BenefitsList.js";
import PreviewCompatibleImage from "../components/utilities/PreviewCompatibleImage";
import Famous from "../components/Famous.js";
import Introduction from "../components/Introduction.js";
import IconSelect from "../components/utilities/IconSelect.js";
import CountdownTimer from "../components/CountdownTimer";
import BrandButton from "../components/BrandButton.js";
import Signup from "../components/Signup.js";

export const OptInPageTemplate = ({ data }) => {
  return (
    <Layout
      imageObject={data.headerObject.imageObject}
      seoObject={data.seoObject}
    >
      {/*------------------------- START MAIN CONTENT -------------------------*/}
      <div>
        {/*------------------------- FIRST ELEMENT REFACTORED - heroObject -------------------------*/}
        <HeroSection isSectionComponent={false}>
          <BackgroundImageDivResponsive
            imageObject={data.heroObject.imageObject}
            className="h-full"
          >
            {/* wrapper div to add padding */}
            <div className="py-3 sm:py-10 h-full lg:flex lg:flex-col lg:justify-center">
              <div className="flex flex-row flex-wrap justify-around xl:justify-start content-between items-stretch box-border lg:mx-10 xl:mx-0">
                <article className="w-1/2 flex flex-shrink" />
                <article
                  className={`bg-white bg-opacity-75 p-6 rounded-xl w-11/12 max-w-xl`}
                >
                  <RenderMarkdown
                    markdownContent={data.heroObject.title}
                    whitespace="normal"
                    className="pb-2 sm:pb-5"
                  />
                  <RenderMarkdown
                    markdownContent={data.heroObject.subtitle}
                    whitespace="normal"
                    className="pb-2 sm:pb-5"
                  />

                  <CountdownTimer
                    className="m-2 xl:m-5"
                    countdownTitle={data.heroObject.countdownTitle}
                    date={data.heroObject.date}
                  />

                  <BrandButton>
                    <RenderMarkdown
                      markdownContent={data.heroObject.cta}
                      isMarkdown={false}
                      whitespace="normal"
                    />
                  </BrandButton>
                </article>
              </div>
            </div>
          </BackgroundImageDivResponsive>
        </HeroSection>

        {/*------------------------- SECOND ELEMENT - benefitsObject -------------------------*/}
        <Section>
          <div className="flex flex-col items-center">
            <div className="pb-5 sm:pb-8">
              <PreviewCompatibleImage
                imageObject={data.benefitsObject.imageObject}
              />
            </div>

            <article className="flex flex-col items-center">
              <RenderMarkdown
                markdownContent={data.benefitsObject.description}
                isMarkdown={true}
                className="text-center"
              />

              <FlexGroup
                ElementName={IconAndLabelCMS}
                list={data.benefitsObject.benefitsList}
              />

              <article className="flex flex-col items-center my-10">
                <RenderMarkdown
                  markdownContent={data.benefitsObject.ctaTitle}
                  className="text-center"
                />

                <BrandButton>
                  <RenderMarkdown
                    markdownContent={data.benefitsObject.cta}
                    isMarkdown={false}
                    whitespace="normal"
                  />
                </BrandButton>
              </article>
            </article>
          </div>
        </Section>

        {/*------------------------- THIRD ELEMENT - statsObject -------------------------*/}
        <Section className="bg-brand-mint py-10">
          <RenderMarkdown
            markdownContent={data.statsObject.title}
            className="text-center"
          />

          <FlexGroup
            ElementName={StatsAndLabelCMS}
            list={data.statsObject.statisList}
          />
        </Section>

        {/*------------------------- Fourth ELEMENT - introductionObject -------------------------*/}
        <Section className="bg-brand-beige-40">
          <Introduction introductionObject={data.introductionObject} />
        </Section>

        {/*------------------------- FIFTH ELEMENT - famousObject -------------------------*/}
        <Section className="bg-brand-mint">
          <Famous famousObject={data.famousObject} />
        </Section>

        {/*------------------------- SEVENTH ELEMENT - featuresObject -------------------------*/}
        <Section>
          <RenderMarkdown
            markdownContent={data.featuresObject.title}
            className="text-center"
          />
          <FlexGroup
            ElementName={FeaturesElement}
            list={data.featuresObject.featuresList}
          />
          <div className="flex justify-center">
            <BrandButton>
              <RenderMarkdown
                markdownContent={data.featuresObject.cta}
                isMarkdown={false}
                whitespace="normal"
              />
            </BrandButton>
          </div>
        </Section>

        {/*------------------------- EIGHTH ELEMENT - reviewsObject -------------------------*/}
        <Section className="bg-brand-beige-40">
          <div className="flex flex-col items-center ">
            <RenderMarkdown
              markdownContent={data.reviewsObject.title}
              className="text-center"
            />

            <FlexGroup
              ElementName={PreviewCompatibleImage}
              list={data.reviewsObject.reviewList}
              className="flex-col"
              classNameElement="my-3 sm:my-5 max-w-xl"
            />

            <BrandButton className="mt-5">
              <RenderMarkdown
                markdownContent={data.reviewsObject.cta}
                isMarkdown={false}
                whitespace="normal"
              />
            </BrandButton>
          </div>
        </Section>

        {/*------------------------- NINTH ELEMENT - benefitsObject2 -------------------------*/}
        <Section className="flex flex-col items-center">
          <BenefitsList benefitsObject={data.benefitsObject2} />
        </Section>

        {/*------------------------- TENTH ELEMENT - signupObject -------------------------*/}
        <Section id="start" className="bg-brand-beige-40">
          <Signup signupObject={data.signupObject} />
        </Section>

        {/*------------------------- LAST ELEMENT - citationObject -------------------------*/}

        <Section className="bg-brand-mint">
          <hr className="w-full border-white my-5" />
          <RenderMarkdown
            markdownContent={data.citationObject.citation}
            isMarkdown={true}
          />
          <hr className="w-full border-white mb-5" />
        </Section>
      </div>
    </Layout>
  );
};

// ------------------------- Components & Functions -------------------------
function StatsAndLabelCMS({ number, description }) {
  return (
    <article className="flex flex-col items-center w-full px-5 pt-5 sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/4 font-normal">
      <p className="text-5xl">{number.toLocaleString()}</p>
      <RenderMarkdown
        markdownContent={description}
        whitespace="normal"
        className="text-center pt-5"
      />
    </article>
  );
}

function FeaturesElement({ imageObject, description }) {
  return (
    <article className="flex flex-col items-center text-center p-0 sm:p-5 w-full sm:w-1/2 md:w-1/3 xl:w-1/3">
      <div className="h-40 md:h-48 lg:h-60 flex flex-col justify-center mb-3">
        <PreviewCompatibleImage
          imageObject={imageObject}
          className="rounded-3xl max-h-full max-w-xs sm:max-w-none"
        />
      </div>
      <RenderMarkdown markdownContent={description} isMarkdown={true} />
    </article>
  );
}

function IconAndLabelCMS({ iconName, title }) {
  return (
    <div className="flex flex-col items-center w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/6">
      <IconSelect iconName={iconName} className="h-20" />
      <div className="h-24 sm:h-20">
        <RenderMarkdown
          markdownContent={title}
          className="pt-4 text-center"
        ></RenderMarkdown>
      </div>
    </div>
  );
}

const OptInPage = ({ data }) => {
  return <OptInPageTemplate data={data.markdownRemark.frontmatter} />;
};

export default OptInPage;

export const pageQuery = graphql`
  query OptInPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        seoObject {
          slug
          domain
          description
          index
          title
          imageObject {
            alt
            image {
              childImageSharp {
                resize(width: 1200) {
                  src
                  height
                  width
                }
              }
            }
          }
        }
        headerObject {
          imageObject {
            alt
            image {
              childImageSharp {
                gatsbyImageData(placeholder: NONE)
              }
            }
          }
        }
        heroObject {
          imageObject {
            image {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            alt
            mobileImage {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
          cta
          countdownTitle
          date
          subtitle
          title
        }
        benefitsObject {
          cta
          ctaTitle
          benefitsList {
            title
            iconName
          }
          imageObject {
            alt
            image {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
          description
        }
        benefitsObject2 {
          list {
            description
            iconName
          }
          title
        }
        citationObject {
          citation
        }
        famousObject {
          title
          imageObject {
            alt
            image {
              childImageSharp {
                gatsbyImageData
              }
            }
            mobileImage {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        introductionObject {
          introductionText
          imageSubtext
          imageObject {
            alt
            image {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          signatureImageObject {
            alt
            image {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        reviewsObject {
          cta
          title
          reviewList {
            imageObject {
              alt
              image {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
        signupObject {
          cta
          ctaSubtitle
          subtitle
          title
          imageObject {
            alt
            image {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          funnelId
          href
        }
        statsObject {
          title
          statisList {
            number
            description
          }
        }
        featuresObject {
          title
          cta
          featuresList {
            description
            imageObject {
              alt
              image {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }
  }
`;
