import React from "react";

import RenderMarkdown from "./utilities/RenderMarkdown";
import PreviewCompatibleImage from "./utilities/PreviewCompatibleImage";

const Reviews = ({ reviewsObject }) => {
  return (
    <div className="flex flex-col align-center">
      <RenderMarkdown
        markdownContent={reviewsObject.title}
        className="text-center"
      />
      <div className="flex flex-col lg:flex-row justify-evenly lg:flex-wrap">
        {reviewsObject.reviewList.map((el, index) => (
          <PreviewCompatibleImage
            className={`w-full lg:w-1/2 max-w-xl p-5 mx-auto ${
              index > 4 && "hidden lg:block"
            }`}
            imageObject={el.imageObject}
            loading="lazy"
          />
        ))}
      </div>
    </div>
  );
};

export default Reviews;
