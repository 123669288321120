import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout.js";
import Video from "../components/Video.js";
import RenderMarkdown from "../components/utilities/RenderMarkdown.js";
import Section from "../components/Section.js";
import ProgressBar from "../components/ProgressBar.js";
import ContentsList from "../components/ContentsList.js";
import Reviews from "../components/Reviews.js";
import Overview from "../components/Overview.js";
import PricingSingle from "../components/PricingSingle.js";
import HeroSection from "../components/HeroSection.js";
import BrandButton from "../components/BrandButton.js";

export const UpsellPageTemplate = ({ data }) => {
  data.pricingObject.linkUrl = data.configObject.linkUrl;
  data.heroObject.linkUrl = data.configObject.linkUrl;

  return (
    <Layout
      imageObject={data.headerObject.imageObject}
      seoObject={data.seoObject}
    >
      <HeroSection className="bg-brand-beige-40">
        <div className="space-y-2 sm:space-y-5 flex flex-col">
          <ProgressBar progressObject={data.heroObject.progressObject} />
          <RenderMarkdown
            markdownContent={data.heroObject.title}
            className="text-center whitespace-normal space-y-2 sm:space-y-4 text-lg sm:text-4xl"
            isMarkdown={true}
            whitespace="normal"
          />
          {data.heroObject.subtitle && (
            <RenderMarkdown
              markdownContent={data.heroObject.subtitle}
              className="text-center text-sm sm:text-3xl"
              isMarkdown={true}
              whitespace="normal"
            />
          )}
          <Video videoObject={data.heroObject.video} loading="eager" />
          <div className="flex justify-center">
            <BrandButton href={data.heroObject.linkUrl}>
              <RenderMarkdown
                markdownContent={data.heroObject.cta}
                whitespace="normal"
              />
            </BrandButton>
          </div>
          {data.heroObject.ctaSecondaryObject.linkText &&
            data.heroObject.ctaSecondaryObject.linkUrl && (
              <div className="flex justify-center">
                <BrandButton
                  href={data.heroObject.ctaSecondaryObject.linkUrl}
                  color="bg-white"
                >
                  <RenderMarkdown
                    markdownContent={
                      data.heroObject.ctaSecondaryObject.linkText
                    }
                    className="text-brand-black"
                    isMarkdown={false}
                    whitespace="normal"
                  />
                </BrandButton>
              </div>
            )}
        </div>
      </HeroSection>
      <Section>
        <RenderMarkdown markdownContent={data.benefitsObject.description} />
        {/* <BenefitsList benefitsObject={data.benefitsObject} /> */}
      </Section>
      <Section className="bg-brand-mint !px-0 !sm:px-10">
        <ContentsList contentsObject={data.contentsObject} />
      </Section>
      {data.reviewsObject && (
        <Section className="bg-brand-beige-40">
          <Reviews
            reviewsObject={data.reviewsObject}
            href={data.configObject.linkUrl}
          />
        </Section>
      )}
      <Section id="start">
        <Overview overviewObject={data.overviewObject} />
      </Section>
      <Section>
        <PricingSingle pricingObject={data.pricingObject} />
      </Section>
    </Layout>
  );
};

const UpsellPage = ({ data }) => {
  return <UpsellPageTemplate data={data.markdownRemark.frontmatter} />;
};

export default UpsellPage;

export const pageQuery = graphql`
  query UpsellPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        configObject {
          linkUrl
        }
        seoObject {
          slug
          domain
          description
          index
          title
          imageObject {
            alt
            image {
              childImageSharp {
                resize(width: 1200) {
                  src
                  height
                  width
                }
              }
            }
          }
        }
        headerObject {
          imageObject {
            alt
            image {
              childImageSharp {
                gatsbyImageData(placeholder: NONE)
              }
            }
          }
        }
        heroObject {
          cta
          ctaSecondaryObject {
            linkText
            linkUrl
          }
          subtitle
          title
          progressObject {
            percentage
            title
          }
          video {
            format
            cloudflareId
            hasGifThumbnail
          }
        }
        benefitsObject {
          description
        }
        contentsObject {
          imageObject {
            alt
            image {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          title
          list {
            description
            imageObject {
              alt
              image {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
        reviewsObject {
          cta
          title
          reviewList {
            imageObject {
              alt
              image {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
        overviewObject {
          title
          description
          imageObject {
            alt
            image {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        pricingObject {
          title
          ctaSubtitle
          cta
        }
      }
    }
  }
`;
