import React from "react";

import { parse } from "marked";

const RenderMarkdown = ({
  markdownContent,
  className = "",
  isMarkdown = true,
  // whitespace prop gets overwritten by CSS class whitespace to enable breakpoints
  whitespace = "pre-line", // other values for whitespace: "normal", "nowrap", "pre", "pre-line", "pre-wrap"
}) => {
  if (!(typeof markdownContent !== "undefined" && markdownContent)) {
    return;
  }

  const htmlContent = parse(markdownContent);

  return (
    <div
      dangerouslySetInnerHTML={{ __html: htmlContent }}
      className={`max-w-2xl mx-auto whitespace-${whitespace} ${
        isMarkdown ? "markdown" : ""
      } ${className}`}
    />
  );
};

export default RenderMarkdown;

// all tailwind whitespace classes written out otherwise they may not work due to CSS class purging
// whitespace-normal
// whitespace-nowrap
// whitespace-pre
// whitespace-pre-line
// whitespace-pre-wrap