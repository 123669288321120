import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout.js";
import Section from "../components/Section.js";
import HeroSection from "../components/HeroSection";
import PreviewCompatibleImage from "../components/utilities/PreviewCompatibleImage.js";
import RenderMarkdown from "../components/utilities/RenderMarkdown.js";
import Video from "../components/Video.js";
import Steps from "../components/Steps.js";
import MoreCourses from "../components/MoreCourses.js";
import FAQs from "../components/FAQs";

export const ThankYouPageTemplate = ({ data }) => {
  return (
    <Layout
      imageObject={data.headerObject.imageObject}
      seoObject={data.seoObject}
    >
      <HeroSection>
        <div className="space-y-5 sm:space-y-10">
          <RenderMarkdown
            markdownContent={data.heroObject.title}
            className="text-center"
            whitespace="normal"
          />
          {data.heroObject.video ? (
            <Video videoObject={data.heroObject.video} loading="eager" />
          ) : (
            <PreviewCompatibleImage
              imageObject={data.heroObject.imageObject}
              loading="eager"
            />
          )}
        </div>
      </HeroSection>

      <Section className="bg-brand-beige-40">
        <Steps stepsObject={data.stepsObject} />
      </Section>

      {data.qAndAObject && data.qAndAObject.qAndA.length > 0 && (
        <Section className="bg-white">
          <FAQs faqsObject={data.qAndAObject} />
        </Section>
      )}

      {data.showCourses && (
        <Section className="bg-brand-mint">
          <MoreCourses />
        </Section>
      )}
    </Layout>
  );
};

const ThankYouPage = ({ data }) => {
  return <ThankYouPageTemplate data={data.markdownRemark.frontmatter} />;
};

export default ThankYouPage;

export const pageQuery = graphql`
  query ThankYouPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        seoObject {
          slug
          domain
          description
          index
          title
          imageObject {
            alt
            image {
              childImageSharp {
                resize(width: 1200) {
                  src
                  height
                  width
                }
              }
            }
          }
        }
        headerObject {
          imageObject {
            alt
            image {
              childImageSharp {
                gatsbyImageData(placeholder: NONE)
              }
            }
          }
        }
        heroObject {
          video {
            cloudflareId
            hasGifThumbnail
          }
          imageObject {
            alt
            image {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
          title
          subtitle
        }
        stepsObject {
          title
          steps {
            imageObject {
              alt
              image {
                childImageSharp {
                  gatsbyImageData
                }
              }
              linkUrl
            }
            video {
              format
              cloudflareId
              hasGifThumbnail
            }
            description
            anchor
            linkSetList {
              linkText
              linkSet {
                linkUrl
              }
              linkSubtitle
            }
          }
        }
        qAndAObject {
          title
          qAndA {
            question
            answer
          }
        }
        showCourses
      }
    }
  }
`;
