import React from "react";
import RenderMarkdown from "./utilities/RenderMarkdown.js";
import PreviewCompatibleImage from "./utilities/PreviewCompatibleImage.js";

const Introduction = ({ introductionObject, isReversed = false }) => {
  return (
    <div
      className={`text-left mx-auto rounded-xl flex flex-col lg:${
        isReversed ? "flex-row-reverse" : "flex-row"
      }`}
    >
      <div className="mx-auto lg:ml-10 my-10 w-10/12 lg:w-1/4 self-start">
        <PreviewCompatibleImage
          className="rounded-xl"
          imageObject={introductionObject.imageObject}
        />
        <RenderMarkdown
          markdownContent={introductionObject.imageSubtext}
          className="text-xs sm:text-base space-y-1 mt-3 text-[#5e5e5d]"
          isMarkdown={false}
          whitespace="normal"
        />
      </div>

      <article className="font-normal mx-5 sm:mx-10 sm:mt-8 lg:w-2/3">
        <RenderMarkdown markdownContent={introductionObject.introductionText} />
        {introductionObject.signatureImageObject && (
          <div className="max-w-2xl mx-auto">
            <PreviewCompatibleImage
              imageObject={introductionObject.signatureImageObject}
              className="w-1/2 sm:w-1/3"
            />
          </div>
        )}
      </article>
    </div>
  );
};

export default Introduction;
