import React from "react";

export default function Audio({ libsynId, loading = "lazy" }) {
  return (
    <iframe
      title="Libsyn Player"
      loading={loading}
      src={`//html5-player.libsyn.com/embed/episode/id/${libsynId}/height/90/theme/custom/thumbnail/no/direction/backward/render-playlist/no/custom-color/4d6039/menu/no/`}
      height="90"
      className="w-full max-w-md mx-auto border-none"
    />
  );
}
