import React from "react";

const Section = ({
  className = "",
  classNameInnerContainer = "",
  id,
  children,
}) => {
  return (
    <section id={id} className={"px-5 sm:px-10 py-5 sm:py-10 " + className}>
      <div className={"max-w-7xl mx-auto " + classNameInnerContainer}>
        {children}
      </div>
    </section>
  );
};

export default Section;
