import React, { useState } from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout.js";
import HeroSection from "../components/HeroSection.js";
import Section from "../components/Section.js";
import ProgressBar from "../components/ProgressBar.js";
import RenderMarkdown from "../components/utilities/RenderMarkdown.js";
import Video from "../components/Video.js";
import Sharing from "../components/Sharing.js";
import Steps from "../components/Steps.js";

export const SharingPageTemplate = ({ data }) => {
  const [isCompleted, setIsCompleted] = useState(false);

  return (
    <Layout
      imageObject={data.headerObject.imageObject}
      seoObject={data.seoObject}
    >
      <HeroSection className="bg-brand-beige-40">
        <div className="space-y-5 sm:space-y-10">
          <ProgressBar
            progressObject={data.heroObject.progressObject}
            isProgressCompleted={isCompleted}
          />
          <RenderMarkdown
            markdownContent={data.heroObject.title}
            className="text-center"
            whitespace="normal"
          />
          <Video videoObject={data.heroObject.video} loading="eager" />
          <Sharing
            sharingObject={data.heroObject.sharingObject}
            onClickEffect={() => setIsCompleted(true)}
          />
        </div>
      </HeroSection>
      {data.subtitle && (
        <Section>
          <RenderMarkdown markdownContent={data.subtitle} />
        </Section>
      )}
      {data.stepsObject && data.stepsObject.steps.length > 0 && (
        <Section>
          <Steps stepsObject={data.stepsObject} />
        </Section>
      )}
    </Layout>
  );
};

const SharingPage = ({ data }) => {
  return <SharingPageTemplate data={data.markdownRemark.frontmatter} />;
};

export default SharingPage;

export const pageQuery = graphql`
  query SharingPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        seoObject {
          slug
          domain
          description
          index
          title
          imageObject {
            alt
            image {
              childImageSharp {
                resize(width: 1200) {
                  src
                  height
                  width
                }
              }
            }
          }
        }
        headerObject {
          imageObject {
            alt
            image {
              childImageSharp {
                gatsbyImageData(placeholder: NONE)
              }
            }
          }
        }
        heroObject {
          progressObject {
            title
            percentage
            percentageCompleted
          }
          video {
            format
            cloudflareId
            hasGifThumbnail
          }
          title
          sharingObject {
            title
            linkUrl
            whatsApp {
              sharingText
              linkText
            }
          }
        }
        subtitle
        stepsObject {
          title
          steps {
            imageObject {
              alt
              image {
                childImageSharp {
                  gatsbyImageData
                }
              }
              linkUrl
            }
            video {
              format
              cloudflareId
              hasGifThumbnail
            }
            description
            anchor
            linkSetList {
              linkText
              linkSet {
                linkUrl
              }
              linkSubtitle
            }
          }
        }
      }
    }
  }
`;
