import React from "react";

import RenderMarkdown from "./utilities/RenderMarkdown";
import PreviewCompatibleImage from "./utilities/PreviewCompatibleImage";

const Overview = ({ overviewObject, whitespace = "normal" }) => {
  return (
    <div className="space-y-10">
      <RenderMarkdown
        markdownContent={overviewObject.title}
        className="text-center"
        whitespace="normal"
      />
      <div className="flex justify-center">
        <PreviewCompatibleImage
          imageObject={overviewObject.imageObject}
          className="w-4/5 sm:w-3/5 lg:w-1/2"
        />
      </div>
      <RenderMarkdown
        markdownContent={overviewObject.description}
        className="sm:w-4/5 lg:w-2/3 mx-auto"
        whitespace={whitespace}
      />
    </div>
  );
};

export default Overview;
