import React from "react";
import { SvgIcon } from "@material-ui/core";

export default class PbaaLoader extends React.Component {
  render() {
    return (
      <div className="fill-current text-brand-green animate-pbaaLoader">
        <SvgIcon fontSize="large">
          <g>
            <rect fill="none" height="36" width="36" />
          </g>
          <g>
            <g>
              <circle cx="12" cy="6" r="2" />
              <path d="M21,16v-2c-2.24,0-4.16-0.96-5.6-2.68l-1.34-1.6C13.68,9.26,13.12,9,12.53,9h-1.05c-0.59,0-1.15,0.26-1.53,0.72l-1.34,1.6 C7.16,13.04,5.24,14,3,14v2c2.77,0,5.19-1.17,7-3.25V15l-3.88,1.55C5.45,16.82,5,17.48,5,18.21C5,19.2,5.8,20,6.79,20H9v-0.5 c0-1.38,1.12-2.5,2.5-2.5h3c0.28,0,0.5,0.22,0.5,0.5S14.78,18,14.5,18h-3c-0.83,0-1.5,0.67-1.5,1.5V20h7.21 C18.2,20,19,19.2,19,18.21c0-0.73-0.45-1.39-1.12-1.66L14,15v-2.25C15.81,14.83,18.23,16,21,16z" />
            </g>
          </g>
        </SvgIcon>
      </div>
    );
  }
}
