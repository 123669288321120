import React from "react";
import TrackedLink from "./utilities/TrackedLink";
import Section from "./Section";

const Footer = class extends React.Component {
  render() {
    return (
      <footer className="bg-brand-beige-40 text-brand-gray font-normal">
        <Section classNameInnerContainer="text-center pt-20 space-y-5">
          <p className="text-sm sm:text-lg">
            Die Qualität deines Lebens ist weit mehr wert als du glaubst.
          </p>
          <p className="text-sm sm:text-lg">
            Heute ist der Tag für den ersten Schritt!
          </p>
          <p className="text-xs sm:text-base text-left mx-auto sm:w-2/3 lg:w-1/2">
            Wir bei der Achtsamkeitsakademie glauben nicht an das „Wunder über
            Nacht“. Wir wissen, dass nur durch kontinuierliche Arbeit an sich
            selbst ein erfüllteres und wertvolleres Leben erreicht werden kann.
            Wenn du dich dazu entschließt, einen Unterschied zwischen deinem
            Leben und dem Leben deiner Mitmenschen zu machen, werden wir alles
            dafür tun, um dich dabei zu unterstützen. Unsere Online-Seminare,
            Bücher und Meditationen sind dafür ausgelegt, dich auf deinem Weg zu
            begleiten, um dir den maximalen Erfolg und Nutzen zu gewährleisten.
            Ein erfolgreiches Ergebnis benötigt immer deine Mithilfe und
            Bereitschaft. Wir leisten mit umfassenden Strategien,
            Trainingsvideos, Meditationen und hilfreichem Begleitmaterial
            unseren Beitrag. Alle Produkte und Leistungen unserer Firma
            unterliegen dem Bildungs- und Informationszweck. Wir geben keine
            medizinischen Ratschläge! Solltest du unter einer psychischen oder
            physischen Erkrankung leiden, dann suche zuerst einen Arzt deines
            Vertrauens auf. Es handelt sich explizit nicht um Psychotherapie und
            stellt keinen Ersatz dar! Wir bedienen uns Methoden aus der
            Achtsamkeit zwecks Prävention. Transparenz und Vertrauen ist uns
            wichtig! Danke, dass du dir Zeit genommen hast. Bis zum nächsten
            Mal! Bis dahin lebe achtsam, kraftvoll und genieße das Leben!
          </p>
          <div className="flex text-xs sm:text-base justify-center space-x-3">
            <TrackedLink
              className="hover:underline"
              href="https://docs.achtsamkeitsakademie.de/impressum"
            >
              Impressum
            </TrackedLink>
            <span>/</span>
            <TrackedLink
              className="hover:underline"
              href="https://docs.achtsamkeitsakademie.de/datenschutzerklaerung"
            >
              Datenschutz
            </TrackedLink>
          </div>
          <p className="text-xs sm:text-base">
            © {new Date().getFullYear()} Peter Beer GmbH - Alle Rechte
            vorbehalten
          </p>
        </Section>
      </footer>
    );
  }
};

export default Footer;
