import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout.js";
import Section from "../components/Section.js";
import BrandButton from "../components/BrandButton.js";
import RenderMarkdown from "../components/utilities/RenderMarkdown.js";
import Audio from "../components/Audio.js";

export const AudioMessagePageTemplate = ({ data }) => {
  return (
    <Layout seoObject={data.seoObject} isWithHeader={false}>
      <Section>
        <div className="space-y-5 sm:space-y-10 my-10">
          <Audio libsynId={data.heroObject.libsynId} loading="eager" />
          <div className="flex items-center justify-center">
            <BrandButton
              href={data.heroObject.ctaObject.linkUrl}
            >
              <RenderMarkdown
                markdownContent={data.heroObject.ctaObject.linkText}
                isMarkdown={false}
                whitespace="normal"
              />
            </BrandButton>
          </div>
        </div>
      </Section>
    </Layout>
  );
};

const AudioMessagePage = ({ data }) => {
  return <AudioMessagePageTemplate data={data.markdownRemark.frontmatter} />;
};

export default AudioMessagePage;

export const pageQuery = graphql`
  query AudioMessagePage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        seoObject {
          slug
          domain
          description
          index
          title
          imageObject {
            alt
            image {
              childImageSharp {
                resize(width: 1200) {
                  src
                  height
                  width
                }
              }
            }
          }
        }
        heroObject {
          libsynId
          ctaObject {
            linkText
            linkUrl
          }
        }
      }
    }
  }
`;
