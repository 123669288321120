// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-templates-404-page-js": () => import("./../../../src/templates/404-page.js" /* webpackChunkName: "component---src-templates-404-page-js" */),
  "component---src-templates-audio-message-page-js": () => import("./../../../src/templates/audio-message-page.js" /* webpackChunkName: "component---src-templates-audio-message-page-js" */),
  "component---src-templates-day-page-js": () => import("./../../../src/templates/day-page.js" /* webpackChunkName: "component---src-templates-day-page-js" */),
  "component---src-templates-opt-in-page-js": () => import("./../../../src/templates/opt-in-page.js" /* webpackChunkName: "component---src-templates-opt-in-page-js" */),
  "component---src-templates-sharing-page-js": () => import("./../../../src/templates/sharing-page.js" /* webpackChunkName: "component---src-templates-sharing-page-js" */),
  "component---src-templates-thank-you-page-js": () => import("./../../../src/templates/thank-you-page.js" /* webpackChunkName: "component---src-templates-thank-you-page-js" */),
  "component---src-templates-upsell-page-js": () => import("./../../../src/templates/upsell-page.js" /* webpackChunkName: "component---src-templates-upsell-page-js" */),
  "component---src-templates-video-message-page-js": () => import("./../../../src/templates/video-message-page.js" /* webpackChunkName: "component---src-templates-video-message-page-js" */),
  "component---src-templates-video-page-js": () => import("./../../../src/templates/video-page.js" /* webpackChunkName: "component---src-templates-video-page-js" */)
}

