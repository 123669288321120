import React from "react";

import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/outline";
import RenderMarkdown from "./utilities/RenderMarkdown";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function FAQs({ faqsObject, className }) {
  if (faqsObject.qAndA === undefined || faqsObject.qAndA.length === 0) {
    return;
  }

  const faqsList = faqsObject.qAndA;

  return (
    <div className={`mx-auto ${className}`}>
      <div className="max-w-4xl mx-auto divide-y divide-brand-black">
        <RenderMarkdown
          className="text-center"
          whitespace="normal"
          markdownContent={faqsObject.title}
        />

        <dl className="mt-6 space-y-6 divide-y divide-brand-black">
          {faqsList.map((faq) => (
            <Disclosure as="div" key={faq.question} className="pt-6 last:pb-6">
              {({ open }) => (
                <>
                  <dt>
                    <Disclosure.Button className="text-left flex justify-between w-full">
                      <RenderMarkdown
                        className="text-base w-full mx-0"
                        markdownContent={faq.question}
                        whitespace="normal"
                      />
                      <span className="ml-6 h-7 flex items-center">
                        <ChevronDownIcon
                          className={classNames(
                            open ? "-rotate-180" : "rotate-0",
                            "h-6 w-6 transform"
                          )}
                          aria-hidden="true"
                        />
                      </span>
                    </Disclosure.Button>
                  </dt>
                  <Disclosure.Panel as="dd" className="mt-2 pr-12 max-w-2xl">
                    <RenderMarkdown
                      className="text-base w-full"
                      markdownContent={faq.answer}
                    />
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          ))}
        </dl>
      </div>
    </div>
  );
}
