import React from "react";
import { Helmet } from "react-helmet";

function Seo({ seoObject }) {
  return (
    <Helmet
      htmlAttributes={{
        lang: "de-DE",
      }}
      title={seoObject.title}
      link={[
        {
          rel: "canonical",
          href: "https://" + seoObject.domain + seoObject.slug,
        },
      ]}
      meta={[
        {
          name: `description`,
          content: seoObject.description,
        },
        {
          name: "theme-color",
          content: "#fff",
        },
        {
          property: `og:title`,
          content: seoObject.title,
        },
        {
          property: `og:description`,
          content: seoObject.description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:url`,
          content: "https://" + seoObject.domain + seoObject.slug,
        },
        {
          name: `twitter:title`,
          content: seoObject.title,
        },
        {
          name: `twitter:description`,
          content: seoObject.description,
        },
      ]
        .concat(
          seoObject.imageObject.image.childImageSharp.resize.src
            ? [
                {
                  property: "og:image",
                  content:
                    seoObject.imageObject.image.childImageSharp.resize.src,
                },
                {
                  property: "og:image:alt",
                  content: seoObject.imageObject.alt,
                },
                {
                  property: "og:image:width",
                  content:
                    seoObject.imageObject.image.childImageSharp.resize.width,
                },
                {
                  property: "og:image:height",
                  content:
                    seoObject.imageObject.image.childImageSharp.resize.height,
                },
                {
                  property: "twitter:image",
                  content:
                    seoObject.imageObject.image.childImageSharp.resize.src,
                },
                {
                  property: "twitter:image:alt",
                  content: seoObject.imageObject.alt,
                },
                {
                  property: "twitter:image:width",
                  content:
                    seoObject.imageObject.image.childImageSharp.resize.width,
                },
                {
                  property: "twitter:image:height",
                  content:
                    seoObject.imageObject.image.childImageSharp.resize.height,
                },
                {
                  name: "twitter:card",
                  content: "summary_large_image",
                },
              ]
            : [
                {
                  name: "twitter:card",
                  content: "summary",
                },
              ]
        )
        .concat(
          seoObject.index
            ? [{ name: "robots", content: "index" }]
            : [{ name: "robots", content: "noindex" }]
        )}
    />
  );
}

export default Seo;
