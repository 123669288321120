import React from "react";
import PreviewCompatibleImage from "./utilities/PreviewCompatibleImage";
import BrandButton from "./BrandButton";
import RenderMarkdown from "./utilities/RenderMarkdown";
import FlexGroup from "./utilities/FlexGroup";
import TrackedLink from "./utilities/TrackedLink";

export default function FurtherInteractions({ furtherInteractionsObject }) {
  const list = [
    { ...furtherInteractionsObject.fbInteractionObject, id: 1 },
    { ...furtherInteractionsObject.premiumInteractionObject, id: 2 },
  ];

  return (
    <article className="flex flex-col sm:flex-row space-y-5 sm:space-y-0 h-full">
      <FlexGroup ElementName={FurtherInteractionsElement} list={list} />
    </article>
  );
}

function FurtherInteractionsElement({ imageObject, ctaObject }) {
  if (!ctaObject) {
    return null;
  }
  return (
    <div className="w-full sm:w-1/2 p-5 space-y-5 sm:space-y-10">
      <TrackedLink href={ctaObject.linkUrl}>
        <PreviewCompatibleImage imageObject={imageObject} />
      </TrackedLink>
      <BrandButton href={ctaObject?.linkUrl}>
        <RenderMarkdown
          markdownContent={ctaObject.linkText}
          isMarkdown={false}
          whitespace="normal"
        />
      </BrandButton>
    </div>
  );
}
