import React, { useState, useEffect } from "react";

import RenderMarkdown from "./utilities/RenderMarkdown";

import useCountdown from "../functions/hooks/useCountdown";
import convertCMSDateToEpoch from "../functions/convertCMSDateToEpoch";

export default function CountdownTimer({
  date,
  className = "",
  classNameDays = "",
  classNameHours = "",
  classNameMinutes = "",
  classNameSeconds = "",
  countdownTitle = "",
  //TODO: add more reasonable default for classNameDigits
  classNameDigits = "",
}) {
  
  if (typeof date === "string") {
    date = convertCMSDateToEpoch(date);
  }

  const countdownObject = useCountdown(date);

  if (countdownObject.isTimeUp) {
    return null;
  }

  return (
    <>
      <RenderMarkdown
        markdownContent={countdownTitle}
        isMarkdown={true}
        whitespace="normal"
      />
      <div className={`flex flex-row justify-center items-center ${className}`}>
        <CountdownElement
          digits={countdownObject.days}
          title="Tage"
          className={`${classNameDigits} ${classNameDays}`}
        />
        <CountdownElement
          digits={countdownObject.hours}
          title="Stunden"
          className={`${classNameDigits} ${classNameHours}`}
        />
        <CountdownElement
          digits={countdownObject.minutes}
          title="Minuten"
          className={`${classNameDigits} ${classNameMinutes}`}
        />
        <CountdownElement
          digits={countdownObject.seconds}
          title="Sekunden"
          className={`${classNameDigits} ${classNameSeconds}`}
        />
      </div>
    </>
  );
}

function CountdownElement({ digits, title, className = "" }) {
  const [digitsDisplayed, setDigitsDisplayed] = useState(
    numToDisplayableMarkdownH2String(digits)
  );

  useEffect(() => {
    setDigitsDisplayed(numToDisplayableMarkdownH2String(digits));
  }, [digits]);

  return (
    <div className={`flex flex-col items-center mx-1 ${className}`}>
      <RenderMarkdown
        markdownContent={digitsDisplayed}
        isMarkdown="true"
        whitespace="normal"
      />
      <RenderMarkdown
        markdownContent={title}
        isMarkdown="true"
        whitespace="normal"
      />
    </div>
  );
}

function numToDoubleDigitString(number) {
  return number.toLocaleString("de-DE", {
    minimumIntegerDigits: 2,
  });
}

function stringToMarkdownH2(string) {
  return "## " + string;
}

function numToDisplayableMarkdownH2String(number) {
  return stringToMarkdownH2(numToDoubleDigitString(number));
}
