import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import RenderMarkdown from "./utilities/RenderMarkdown";
import BrandButton from "./BrandButton";

export default function MoreCourses() {
  return (
    <>
      <RenderMarkdown
        markdownContent="## Weitere Kurse zum Dranbleiben"
        whitespace="normal"
        className="m-auto text-center"
      />
      <CourseCards cardBackgroundColor="bg-white" />

      <RenderMarkdown
        markdownContent="### oder"
        whitespace="normal"
        className="m-auto text-center"
      />

      <EnrollAACard cardBackgroundColor="bg-white" />
    </>
  );
}

function CourseCards({ cardBackgroundColor }) {
  // because of StaticImage img src can't be passed as prop, so the course cards are hard coded
  const courseList = [
    {
      imgSrc:
        "../img/imported_with_StaticImage/selbstbewusstsein-finden.jpg",
      title: "Selbstbewusstsein finden",
      buttonText: "Jetzt buchen",
      href: "https://buchen.achtsamkeitsakademie.de/forms/BFM-ERH-OY1-J8F",
    },
    {
      imgSrc: "../img/imported_with_StaticImage/Kurs-EFP.jpeg",
      title: "EFP - Emotional Freedom Process",
      buttonText: "Jetzt buchen",
      href: "https://www.achtsamkeitsacademy.de/efp-kurs/",
    },
    {
      imgSrc: "../img/imported_with_StaticImage/Kurs-Angst_loslassen.jpeg",
      title: "Angst loslassen - Frieden finden",
      buttonText: "Jetzt buchen",
      href: "https://www.achtsamkeitsacademy.de/ak20_vk/",
    },
    {
      imgSrc:
        "../img/imported_with_StaticImage/Kurs-Heile_dein_inneres_Kind.jpeg",
      title: "Heile dein Inneres Kind - Kurs",
      buttonText: "Jetzt buchen",
      href: "https://dein-inneres-kind.info/",
    },
  ];

  const classNameArticle =
    "rounded-3xl flex flex-col items-center text-center py-5 px-5 sm:px-8 my-5 mx-auto sm:mx-5 w-11/12 sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5";

  return (
    <div className="flex flex-col sm:flex-row sm:flex-wrap content-center sm:justify-center xl:justify-around">
      <article className={`${classNameArticle} ${cardBackgroundColor}`}>
        <div className="flex flex-col justify-center mb-3">
          <StaticImage
            src="../img/imported_with_StaticImage/selbstbewusstsein-finden.jpg"
            alt="Übersichtsbild des Selbstmitgefühlkurses"
          />
        </div>
        <RenderMarkdown
          markdownContent={courseList[0].title}
          isMarkdown={false}
        />

        <BrandButton href={courseList[0].href}>
          <RenderMarkdown
            markdownContent={courseList[0].buttonText}
            isMarkdown={false}
            whitespace="normal"
          />
        </BrandButton>
      </article>

      <article className={`${classNameArticle} ${cardBackgroundColor}`}>
        <div className="flex flex-col justify-center mb-3">
          <StaticImage
            src="../img/imported_with_StaticImage/Kurs-EFP.jpeg"
            alt="Übersichtsbild des Emotional Freedom Process Kurs"
          />
        </div>
        <RenderMarkdown
          markdownContent={courseList[1].title}
          isMarkdown={false}
        />

        <BrandButton href={courseList[1].href}>
          <RenderMarkdown
            markdownContent={courseList[1].buttonText}
            isMarkdown={false}
            whitespace="normal"
          />
        </BrandButton>
      </article>

      <article className={`${classNameArticle} ${cardBackgroundColor}`}>
        <div className="flex flex-col justify-center mb-3">
          <StaticImage
            src="../img/imported_with_StaticImage/Kurs-Angst_loslassen.jpeg"
            alt="Übersichtsbild des Angst-Loslassen-Kurses"
          />
        </div>
        <RenderMarkdown
          markdownContent={courseList[2].title}
          isMarkdown={false}
        />

        <BrandButton href={courseList[2].href}>
          <RenderMarkdown
            markdownContent={courseList[2].buttonText}
            isMarkdown={false}
            whitespace="normal"
          />
        </BrandButton>
      </article>

      <article className={`${classNameArticle} ${cardBackgroundColor}`}>
        <div className="flex flex-col justify-center mb-3">
          <StaticImage
            src="../img/imported_with_StaticImage/Kurs-Heile_dein_inneres_Kind.jpeg"
            alt="Übersichtsbild des Inneren Kind Kurses"
          />
        </div>
        <RenderMarkdown
          markdownContent={courseList[3].title}
          isMarkdown={false}
        />

        <BrandButton href={courseList[3].href}>
          <RenderMarkdown
            markdownContent={courseList[3].buttonText}
            isMarkdown={false}
            whitespace="normal"
          />
        </BrandButton>
      </article>
    </div>
  );
}

function EnrollAACard({ cardBackgroundColor }) {
  const classNameArticle =
    "rounded-3xl flex flex-col items-center text-center py-5 px-5 sm:px-8 m-5 w-11/12 sm:w-2/3 md:w-5/12 lg:w-1/3 xl:w-1/4 ";
  return (
    <article className={`mx-auto ${classNameArticle} ${cardBackgroundColor}`}>
      <div className="flex flex-col justify-center mb-3">
        <StaticImage
          src="../img/imported_with_StaticImage/Achtsamkeitsakademie-2021.jpeg"
          alt="Übersichtsbild der Achtsamkeitsakademie"
        />
      </div>
      <RenderMarkdown
        markdownContent={"**Deine Mitgliedschaft in der Achtsamkeitsakademie**<br/> (Zugriff auf alle Kurse)"}
        isMarkdown={false}
      />

      <BrandButton
        href={"https://www.achtsamkeitsakademie.de/?utm_medium=website&utm_source=thankyoupage&utm_campaign=morecourses"}
      >
        <RenderMarkdown
          markdownContent={"Jetzt Mitglied werden"}
          isMarkdown={false}
          whitespace="normal"
        />
      </BrandButton>
    </article>
  );
}
