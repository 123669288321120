import React, { useState } from "react";
import RenderMarkdown from "../components/utilities/RenderMarkdown.js";
import { /* FacebookShareButton, */ WhatsappShareButton } from "react-share";
import { ReactComponent as Copy } from "../img/imported_with_webpack/copy-solid.svg";

import { ReactComponent as WhatsAppLogo } from "../img/imported_with_webpack/whatsapp-brands.svg";
// import { ReactComponent as FacebookLogo } from "../img/imported_with_webpack/facebook-f-brands.svg";
import { CopyToClipboard } from "react-copy-to-clipboard";

const Sharing = ({ sharingObject, onClickEffect }) => {
  const [copyMessage, setCopyMessage] = useState("Link kopieren!");
  if (
    !sharingObject.linkUrl.startsWith("https://") &&
    !sharingObject.linkUrl.startsWith("http://")
  ) {
    sharingObject.linkUrl = `https://${sharingObject.linkUrl}`;
  }

  return (
    <>
      <RenderMarkdown
        markdownContent={sharingObject.title}
        className="text-center"
        whitespace="normal"
      />
      <div className="flex flex-col items-center max-w-xl mx-auto space-y-10 sm:text-2xl">
        <WhatsappShareButton
          //Only works with the correct redirect settings
          url={sharingObject.linkUrl + "?utm_medium=website&utm_source=sharing&utm_campaign=whatsapp"}
          title={sharingObject.whatsApp.sharingText}
          beforeOnClick={() => {
            if (window.dataLayer) {
              window.dataLayer.push({
                event: "sharing",
                plattform: "whatsapp",
                origin: window.location.pathname,
                branch: document.getElementById("#splittestBranchData")?.value,
              });
            }
          }}
          onShareWindowClose={() => {
            onClickEffect();
          }}
        >
          <div className="flex items-center space-x-3 sm:space-x-4 rounded-md bg-[#25D366] text-white p-5 transform duration-300 ease-out hover:scale-110">
            <WhatsAppLogo className="h-10 sm:h-8 fill-current" />
            <RenderMarkdown
              isMarkdown={false}
              whitespace="normal"
              markdownContent={sharingObject.whatsApp.linkText}
              className="text-lg sm:text-2xl"
            />
          </div>
        </WhatsappShareButton>
        <RenderMarkdown
          markdownContent={
            sharingObject.subtitle ||
            "## Du kannst den Link zum Event auch kopieren und direkt verschicken:"
          }
          className="text-center"
          whitespace="normal"
        />
        <CopyToClipboard
          //Only works with the correct redirect setting
          text={sharingObject.linkUrl + "?utm_medium=website&utm_source=sharing&utm_campaign=copylink"}
          onCopy={() => {
            setCopyMessage("Kopiert!");
            if (window.dataLayer) {
              window.dataLayer.push({
                event: "sharing",
                plattform: "copylink",
                origin: window.location.pathname,
                branch: document.getElementById("#splittestBranchData")?.value,
              });
            }
          }}
        >
          <button className="flex items-center space-x-3 sm:space-x-4 rounded-md bg-[#707070] text-white p-5 transform duration-300 ease-out hover:scale-110 font-normal">
            <Copy className="h-10 sm:h-8 fill-current" />
            <RenderMarkdown
              isMarkdown={false}
              whitespace="normal"
              markdownContent={copyMessage}
              className="text-lg sm:text-2xl"
            />
          </button>
        </CopyToClipboard>
      </div>
    </>
  );
};

export default Sharing;
