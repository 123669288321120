import React from "react";

import RenderMarkdown from "./utilities/RenderMarkdown";
import TrackedLink from "./utilities/TrackedLink";

import { ReactComponent as Arrow } from "../img/imported_with_webpack/arrow-left.svg";

export default function ButtonDayPageNav({
  text,
  className = "",
  isActive = true,
  onClick,
  href,
  reverse = false,
}) {
  const classNameActive =
    "bg-white transform hover:scale-[1.02] duration-100 ease-out text-brand-black shadow-xl";
  const classNameInactive =
    "bg-white text-brand-beige-100 shadow-xl pointer-events-none";

  return (
    <TrackedLink
      className={`${
        isActive ? classNameActive : classNameInactive
      }  px-2 sm:px-5 py-2 rounded-xl block text-center text-lg sm:text-2xl ${className}`}
      onClick={onClick}
      href={href}
    >
      <div
        className={`flex ${
          reverse ? "flex-row-reverse" : "flex-row"
        } justify-center content-center space-x-0 sm:space-x-3 `}
      >
        <div className={`self-center rounded-full bg-white`}>
          <Arrow
            className={`h-8 sm:h-12 w-8 sm:w-12 px-0 ${
              reverse && "transform rotate-180"
            }`}
          />
        </div>
        <RenderMarkdown
          markdownContent={text}
          isMarkdown={false}
          whitespace="normal"
          className="font-bold flex flex-col justify-center"
        />
      </div>
    </TrackedLink>
  );
}
