import React from "react";

import { ReactComponent as Freiheit } from "../../img/imported_with_webpack/Freiheit.svg";
import { ReactComponent as Intelligenz } from "../../img/imported_with_webpack/Intelligenz.svg";
import { ReactComponent as Körper } from "../../img/imported_with_webpack/Koerper.svg";
import { ReactComponent as Harmonie } from "../../img/imported_with_webpack/Harmonie.svg";
import { ReactComponent as Lebensfreude } from "../../img/imported_with_webpack/Lebensfreude.svg";
import { ReactComponent as Lösungen } from "../../img/imported_with_webpack/Loesungen.svg";
import { ReactComponent as Selbstliebe } from "../../img/imported_with_webpack/Selbstliebe.svg";
import { ReactComponent as Zukunft } from "../../img/imported_with_webpack/Zukunft.svg";

import { ReactComponent as Leaf } from "../../img/imported_with_webpack/leaf-solid.svg";
import { ReactComponent as StreetView } from "../../img/imported_with_webpack/street-view-solid.svg";
import { ReactComponent as Suitcase } from "../../img/imported_with_webpack/suitcase-solid.svg";
import { ReactComponent as DoubleUp } from "../../img/imported_with_webpack/angle-double-up-solid.svg";
import { ReactComponent as UserPlus } from "../../img/imported_with_webpack/user-plus-solid.svg";

import { ReactComponent as Check } from "../../img/imported_with_webpack/check-solid.svg";

import { ReactComponent as CircleGreen } from "../../img/imported_with_webpack/circle-green.svg";
import { ReactComponent as Enzo } from "../../img/imported_with_webpack/enzo.svg";
import { ReactComponent as CircleGreenOne } from "../../img/imported_with_webpack/circle-green-1.svg";
import { ReactComponent as CircleGreenTwo } from "../../img/imported_with_webpack/circle-green-2.svg";
import { ReactComponent as CircleGreenThree } from "../../img/imported_with_webpack/circle-green-3.svg";
import { ReactComponent as CheckCircledGold } from "../../img/imported_with_webpack/check-circled-gold.svg";

const IconSelect = ({ iconName, className }) => {
  switch (iconName) {
    case "freiheit":
      return <Freiheit className={className} />;
    case "intelligenz":
      return <Intelligenz className={className} />;
    case "körper":
      return <Körper className={className} />;
    case "harmonie":
      return <Harmonie className={className} />;
    case "lebensfreude":
      return <Lebensfreude className={className} />;
    case "lösungen":
      return <Lösungen className={className} />;
    case "selbstliebe":
      return <Selbstliebe className={className} />;
    case "zukunft":
      return <Zukunft className={className} />;

    case "leaf":
      return <Leaf className={className} />;
    case "streetview":
      return <StreetView className={className} />;
    case "suitcase":
      return <Suitcase className={className} />;
    case "doubleup":
      return <DoubleUp className={className} />;
    case "userplus":
      return <UserPlus className={className} />;

    case "check":
      return <Check className={className} />;

    case "circle-green":
      return <CircleGreen className={className} />;
    case "enzo":
      return <Enzo className={className} />;
    case "circle-green-1":
      return <CircleGreenOne className={className} />;
    case "circle-green-2":
      return <CircleGreenTwo className={className} />;
    case "circle-green-3":
      return <CircleGreenThree className={className} />;
    case "check-circled-gold":
      return <CheckCircledGold className={className} />;

    default:
      throw Error(`Icon ${iconName} not found, provide valid icon name`);
  }
};

export default IconSelect;
