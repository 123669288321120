import React from "react";
import { getImage } from "gatsby-plugin-image";

import { BgImage, convertToBgImage } from "gbimage-bridge";
import BackgroundImage from "gatsby-background-image";
// to get tailwind config for BackgroundImage
import resolveConfig from "tailwindcss/resolveConfig";
import tailwindConfig from "../../tailwind.config";

export function BackgroundImageDiv({ imageObject, children }) {
  // image needs to be converted to backgroundimage
  const image = getImage(imageObject.image);
  const backgroundImageAlt = imageObject.alt;

  // converting
  const backgroundImage = convertToBgImage(image);
  return (
    <BackgroundImage
      Tag="section"
      {...backgroundImage}
      preserveStackingContext
      alt={backgroundImageAlt}
      loading="eager"
    >
      <div>{children}</div>
    </BackgroundImage>
  );
}

export function BackgroundImageDivResponsive({
  imageObject,
  children,
  className = "",
}) {
  // getting breakpoint from tailwind config
  const lgBreakpoint = resolveConfig(tailwindConfig).theme.screens.lg; // TODO: using babel-plugin-preval instead of resolveConfig -> https://tailwindcss.com/docs/configuration#referencing-in-java-script
  // const xlBreakpoint = resolveConfig(tailwindConfig).theme.screens.xl; // TODO: using babel-plugin-preval instead of resolveConfig -> https://tailwindcss.com/docs/configuration#referencing-in-java-script

  const sources = [
    getImage(imageObject.mobileImage),
    {
      ...getImage(imageObject.image),
      media: `(min-width: ${lgBreakpoint})`,
    },
  ];

  return (
    <BgImage image={sources} className={className}>
      {children}
    </BgImage>
  );
}
